/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    strong: "strong",
    br: "br",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Vagas da Semana: Confira as Melhores Vagas do Vendoor"), "\n", React.createElement(_components.p, null, "O Vendoor conta com mais de 120 vagas abertas, principalmente na cidade de Goiânia e região metropolitana. Se você está em busca de uma nova oportunidade no mercado de trabalho, não pode deixar de conferir as excelentes vagas que selecionamos para você esta semana. Veja abaixo algumas das principais oportunidades e como se candidatar."), "\n", React.createElement(_components.p, null, "Vagas de emprego na cidade de Goiânia, Goiás."), "\n", React.createElement(_components.h2, null, "Estoquista"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Local:"), " Próximo ao Setor Marista em Goiânia, Goiás ", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Salário:"), " R$ 1.676 (fixo) ", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Descrição da vaga:"), " Recepcionar, conferir e armazenar produtos e materiais, além de acompanhar, controlar, gerenciar e realizar lançamentos de movimentação e cotação. ", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Requisitos:"), " Ensino médio, experiência na função com conhecimento de peças e conhecimento básico em Excel. ", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Benefícios:"), " Vale-transporte, vale-alimentação e plano de incentivo"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/vagas/estoquista-avus-motorsports/"
  }, "Clique aqui"), " para se candidatar."), "\n", React.createElement(_components.h2, null, "Analista Fiscal"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Local:"), " Setor Bueno em Goiânia, Goiás ", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Salário:"), " De R$ 3.260 até R$ 4.614 ", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Descrição da vaga:"), " Responsável por fazer as apurações estaduais, federais, municipais ", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Requisitos:"), " Experiência em Excel Intermediário/avançado. Sistema Domínio, ICMS, PIS E COFINS, ISS, IRPJ, ICSLL, DCTF, EFD REINF, REST, DMS, SPED FISCAL E SPED CONTRIBUIÇÕES. ", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Benefícios:"), " Vale alimentação, plano de saúde, plano odontológico, day off e vale combustível."), "\n", React.createElement(_components.p, null, "Candidate-se agora, ", React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/vagas/analista-fiscal-lobe-consultoria-582/"
  }, "clique aqui.")), "\n", React.createElement(_components.h2, null, "Auxiliar De Serviços Gerais - Limpeza"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Local:"), " Jardim Goiás em Goiânia, Goiás ", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Salário:"), " R$ 1.800 (fixo) ", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Descrição da vaga:"), " Responsável pela limpeza de utensílios, manutenção da organização da área de trabalho, limpeza de banheiros, pisos e paredes. ", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Benefícios:"), " Oferecemos alimentação (café da manhã e tarde), vale transporte mensal e plano Uniodonto."), "\n", React.createElement(_components.p, null, "Candidate-se agora, ", React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/vagas/auxiliar-de-servicos-gerais-limpeza-biscoitos-pereira/"
  }, "clique aqui.")), "\n", React.createElement(_components.h2, null, "Analista de Cobrança"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Local:"), " Lot. Expansul, Aparecida de Goiânia, Goiás ", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Salário:"), " Entre R$ 1.450 e R$ 2.150 ", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Descrição da vaga:"), " Cobrança de mensalidades escolares (ligação e whatsapp), negociações, confecção de contratos e recibos, alimentação de sistema e planilhas. ", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Benefícios:"), " Oferecemos alimentação (café da manhã e tarde), vale transporte mensal e plano Uniodonto."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/vagas/analista-de-cobranca-stival-advogados/"
  }, "Clique aqui"), " para se candidatar à vaga."), "\n", React.createElement(_components.h2, null, "Como se Candidatar"), "\n", React.createElement(_components.p, null, "Para se candidatar a qualquer uma dessas vagas, basta acessar o site do ", React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/"
  }, "Vendoor"), ", e buscar pelas vagas de seu interesse e se candidatar diretamente pela plataforma. Além disso, nosso time de recrutadores está sempre disponível para ajudar e esclarecer qualquer dúvida que você possa ter durante o processo."), "\n", React.createElement(_components.p, null, "Aproveite essa oportunidade para dar um novo rumo à sua carreira. Boa sorte!"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
